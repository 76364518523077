.input {
    padding-left: 10px !important;
}

.inputRoot {
    padding: 7px 10px 7px 0px !important;
    font-size: 0.875rem !important;
    line-height: normal;
}

.startAdornment {
    position: relative;
    min-height: 0 !important;
    margin-top: -2px !important;
}

.helperText {
    margin-left: 0 !important;
}
