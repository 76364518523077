.countryCodeWrapper {
    border: none;
    background: #fff !important;
    position: absolute;
    left: 2px;
    min-height: 37px;
    min-width: 100px;
    top: 2px;
    border-right-width: 0px;
    border-radius: 4px 0px 0px 4px;
}

.TextInput {
    & input,
    ::before,
    ::after {
        font-family: inherit !important;
        border: none !important;
        font-size: 14px !important;
    }
}

.countryCodeFlag {
    font-size: 14px;
    color: #fff;
    margin: 0px;
    padding: 0px;
    margin-left: 6px;
}

.countryCodeSelect {
    font-family: inherit !important;
    border: none !important;
    background: #fff !important;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    padding-left: 6px !important;
    padding-right: 18px !important;
}

.countryCodePopper {
    width: 300px !important;
    z-index: 999999;
}

.countryCodeSelectMenu {
    font-size: 14px !important;
    max-height: 192px;
    overflow-x: auto;
    li {
        font-family: inherit !important;
    }
}
